
.home-container {
    display: flex;
    flex-direction: column;
}

.hero-section {
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}
.footer-container {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    max-width: 300px;
    margin: 10px;
}

.footer-section h3 {
    margin-bottom: 10px;
}

.footer-section p {
    margin: 5px 0;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.social-icons a {
    color: #ecf0f1;
    font-size: 20px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #3498db;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://images.pexels.com/photos/5473298/pexels-photo-5473298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover;
    background-position: center;
    filter: brightness(50%);
    z-index: -1;
}
.content {
    max-width: 800px;
    padding: 20px;
    z-index: 1;
  }
  
 .content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
 .content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .start-trial {
    background-color: #e74c3c;
    color: white;
  }
  
  .log-in {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }


.features-section {
    padding: 4rem 2rem;
    background-color: #f5f5f5;
}

.features-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.feature-card {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.feature-card h3 {
    margin-bottom: 1rem;
    color: #333;
}

.feature-card p {
    color: #666;
}

.languages-section {
    padding: 4rem 2rem;
    background-color: #ffffff;
    text-align: center;
}

.languages-section h2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333;
}

.language-icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.python-color { color: #3776AB; }
.java-color { color: #007396; }
.cpp-color { color: #00599C; }
.js-color { color: #F7DF1E; }
.c-color { color: #A8B9CC; }
.ruby-color { color: #CC342D; }

.language-card {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    margin: 0 10px;
}

.language-card span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
}

.language-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.language-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #333;
    transition: transform 0.3s ease;
}

.language-icon:hover {
    transform: translateY(-5px);
}

.language-icon span {
    font-size: 1rem;
    margin-top: 0.5rem;
}
.code-icon::before { content: "💻"; }
.judge-icon::before { content: "⚖️"; }
.leaderboard-icon::before { content: "🏆"; }
.community-icon::before { content: "👥"; }
.problems{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.problems .row{
    display: flex;

  width: 95vw;
  height: 72px;
  background-color: whitesmoke;
  border: 1px solid blueviolet;
  margin-top: 10px;
  align-items: center;
}

.check{
    position: absolute;
}
.row h3{
    margin-left: 30px;
}
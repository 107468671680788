.big{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #dae0e3;
}
.add-problem{
    background-color: white;
    position: relative;
    top:43px;
    display: flex;
    flex-direction: column;
    width:80vw;
    height:85vh;
    border: 1px solid black;
    border-radius: 20px;
    color:#525e64 ;
}
.addTest{
    display: flex;
}